require('./bootstrap');

//Do not remove, important to send data via ajax
$.ajaxSetup({
    headers: {
        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
    }
});


$(document).scroll(function(e){
    var scrollTop = $(document).scrollTop();
    if(scrollTop > 100){
        $('.navbar').addClass('navbar-scrolled');
    } else {
        $('.navbar').removeClass('navbar-scrolled');
    }
});

$(document).ready(function(){
    $('body').scrollspy({ offset: 450 });

    $("#navbarSupportedContent .nav-link").click(scrollPlace);

    $('.nav-link').on('click', function() {
        $(".navbar-toggler").trigger('click');
    });

	$('.homeintro__slider').slick({
        dots: true
    });

    $(".youtube__slider").slick();
    
});

//Scroll to anchor point
function scrollPlace(e){
    var section = $(this).data("target");
    $("html, body").animate({
        scrollTop: $(section).offset().top-400
    }, {queue: false, duration:500});
    e.preventDefault();
}